html {
  height: 100%;
}

body {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0;
  position: absolute;
  width: 100%;

  .content_wrapper {
    position: relative;
    z-index: 1;
  }

  section.hero-details {
    position: absolute;
    bottom: 90px;
    right: 50px;
    z-index: 2;

    @media screen and (min-height: 735px) {
      position: fixed;
    }

    @media #{$medium-down} {
      display: none;
    }

    a,
    a:hover,
    a:active,
    a:visited {
      text-decoration: none;
    }

    a {
      color: $extra-light-grey;
      cursor: pointer;
      font-size: rem(14);
      font-weight: normal;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.75);

      &:hover {
        color: $green;
      }
    }
  }

  section.background-image {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    left: 0;
    padding-right: 40px;
    position: fixed;
    text-align: right;
    width: 100%;
    height: 100%;

    .fade-out {
      width: 100%;
      height: 100%;
      opacity: 1;
      position: absolute;
      transition: opacity 1s linear;
    }
  }
}
