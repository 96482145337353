.forgot_password {
  p#it-happens {
    display: none;
  }
}
.password_reset_container {
  .continue-to-site {
    display: none;
  }
}

.password_resets_container {
  .activate-password {
    width: 50%;
    margin: 30px auto;
  }
  .g-recaptcha {
    padding-top: 10px;
  }
}
