body.embedded {
  .main_body {
    min-height: initial;
  }

  footer {
    display: none;
  }

  .site_login footer {
    display: block;
  }

  #euCookieDialog {
    display: none !important;
  }
}
