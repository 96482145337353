.password_change_container {
  color: $dark-grey;

  header {
    margin-bottom: 0;
    .logo.wide-header {
      top: auto;
    }
  }
  section.main_body {
    padding: 0;
    background-color: #e8eded;
    text-align: initial;

    .search-bar {
      .search-bar__submit {
        border: 0;
      }

      .media-types {
        label {
          display: inherit;
        }
      }
    }

    .site-width {
      background: transparent;
      border: none;
      margin: 0;
      max-width: none;
      padding: 0;
      width: auto;

      > section {
        max-width: 71.625rem;
        padding: 30px 25px;
        vertical-align: top;
        min-height: 400px;
        margin: 0px auto;

        &.profile {
          > .form-container {
            display: inline-block;
            border: 1px solid $extra-light-grey;
            background-color: $some-grey;
            padding: 25px 40px;
            margin: 10px 30px;
            width: calc(100% - 60px);
            max-width: 624px;

            h1 {
              font-size: 21px;
              color: $dark-grey;
            }

            form {
              label {
                display: none;
              }

              .informational_text {
                font-size: 12px;
              }

              input[type='password'],
              input[type='text'] {
                border: 1px solid black;
                box-shadow: none;
                font-size: 1rem;
                margin-bottom: 10px;
                padding: 10px;
                width: 100%;
              }

              *:not(.search-input) > {
                input + ng-messages > ng-message {
                  color: black;
                  background-color: #ffd900;
                  width: 100%;
                  min-height: 3.7rem;
                  padding: 20px;
                  line-height: 19px;
                  display: block;
                  margin-top: 4px;
                  margin-bottom: 10px;
                }
              }

              *:not(.search-input) > input.ng-invalid.ng-touched,
              input:not(#new_session_username):not(
                  #new_session_password
                ).ng-invalid.ng-touched {
                border: 2px #ffd900 solid;
                margin-bottom: 0;
              }
            }
          }

          @media #{$medium-down} {
            .form-container {
              max-width: none;
              display: block;
            }
          }
        }
      }
    }
  }
}
