@import 'unisporkal-styles/stylesheets/unisporkal_engine/istock/_media_breakpoints';

body.epidemic_sound,
body.triple_scoop_music {
  section.main_body .site-width {
    max-width: 916px;
  }

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
  }

  .logo-image--istock {
    display: block;
    border-right: 1px solid $grey;
    height: 46px;
    margin-right: 15px;
    padding-right: 15px;

    @media #{$istock-medium-up} {
      margin-right: 20px;
      padding-right: 20px;
    }

    .istock-logo {
      position: relative;
      top: 24px;
      width: 108px;
      margin-top: -50%;
    }
  }

  h2 {
    font-size: 1.25em;
  }

  .logo-image--client-logo {
    display: block;

    img {
      width: 212px;
      vertical-align: middle;
    }
  }
}

body.triple_scoop_music {
  .logo-image--client-logo img {
    width: 330px;
  }
}

.double-box-sign-in {
  display: flex;
  flex-direction: column;

  @media #{$istock-medium-up} {
    flex-direction: row;
  }
}

.double-box-sign-in__left,
.double-box-sign-in__right {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;

  @media #{$istock-small-up} {
    margin: 0 30px;
  }

  @media #{$istock-medium-up} {
    width: 50%;
    margin: 0;
  }

  h1,
  body.basic_registrations_container
    section.main_body
    div.site-width
    article.basic_register_form
    &
    h1 {
    font-weight: 500;
    font-size: $h1-font-size - rem(6);

    @media #{$medium-up} {
      font-size: $h1-font-size;
    }
  }

  h2 {
    margin-bottom: rem(24);
  }
}

.double-box-sign-in__left {
  border-width: 1px 0 0 0;
  border-style: solid;
  border-color: $border-grey;
  padding-top: 30px;
  margin-top: 15px;
  order: 1;

  @media #{$istock-medium-up} {
    border-width: 0 1px 0 0;
    padding: 0 50px 0 20px;
    margin-top: 0;
    order: 0;
  }
}

.double-box-sign-in__right {
  @media #{$istock-medium-up} {
    padding: 0 20px 0 50px;
  }

  header {
    margin-bottom: 5px;
  }

  .new_new_session {
    margin-top: 10px;
  }

  footer {
    text-align: center;
  }

  a {
    font-weight: 400;
  }
}

body.music {
  .header {
    margin-bottom: 0;
  }

  .music-provider {
    font-size: 1.25rem;
  }
}
