body.invision {
  font: 16px Arial !important;

  #header-wrapper {
    display: none !important;
  }

  .main_body {
    padding-left: 30px !important;
    padding-right: 30px !important;
    padding: 0 !important;
    min-width: 0 !important;
  }

  h1 {
    color: black;
    font-family: Arial !important;
    line-height: 1em;
    font-size: 30px;
    font-weight: normal !important;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 12px;
  }

  p {
    font-size: 14px;
    font-weight: normal;
  }
  .background-image {
    background-image: none !important;
    background-color: white !important;

    .fade-out {
      display: none !important;
    }
  }

  .site-width {
    border: none !important;
    padding: 0 !important;
    width: auto;
    max-width: 90% !important;
    margin-top: 0px !important;

    .site_login {
      border: none !important;
      float: none;
      padding-right: 0px;
      padding-left: 0px;
      margin-top: 15px !important;

      li {
        margin-bottom: 0;
        label {
          display: none;
        }
        input {
          margin-bottom: 10px;
        }
      }

      .logo {
        display: none;
      }
    }
  }

  .hero-details {
    display: none;
  }

  footer {
    display: none;
  }

  a {
    color: #3594ff;
  }

  h1 {
    text-align: center;
  }

  p {
    text-align: center;
  }

  label {
    font-size: 1.4em;
    font-weight: bold;
    margin-left: 9px;
  }

  button,
  .button,
  .button:hover {
    font-family: Arial !important;
    font-weight: inherit;
    color: white;
    background-color: #3594ff;
    border: #0076f7 1px solid;
    text-transform: none;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 18px;
  }

  input,
  input:focus {
    padding-left: 16px !important;
    height: 44px !important;
    border-radius: 4px !important;
    -webkit-border-radius: 4px !important;
  }

  .account_register {
    float: none !important;
    padding-right: 0px !important;
    padding-left: 0px !important;

    h1 {
      font-size: 14px;
      font-weight: lighter !important;
    }
  }
}
