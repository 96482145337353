section.main_body {
  background-color: transparent;
  padding: 0 30px 100px;
  text-align: left;

  @media #{$small-only} {
    padding: 0px 30px 70px 30px;
  }

  // the only element that surrounds all of the body content.
  .site-width {
    background: white;
    background-clip: padding-box;
    border: 10px solid rgba(12, 13, 13, 0.25);
    border-radius: 10px;
    margin: 20vh auto 10px auto;
    max-width: 425px;
    padding: 30px;
    width: auto;

    .notice {
      background: url(~#{$img_directory}istock/icons/checkmark-green.svg)
        no-repeat;
      @include notification_box;
    }

    .error {
      background: url(~#{$img_directory}istock/icons/exclamation-mark.svg)
        no-repeat;
      @include notification_box;
    }

    @media #{$small-only} {
      margin-top: 20px;
      padding: 20px;
    }
  }
}
