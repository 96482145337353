.password_reset_container {
  font-size: rem(18);
  text-align: center;

  header {
    h1.thank-you {
      font-size: rem(18);
    }
  }
  .signed-in-start-searching {
    display: none;
  }
  .continue-to-site {
    display: block;
  }
}
