// Reset password form
.reset_password {
  header p {
    display: none;
  }
  .informational_text {
    font-size: rem(12);
    line-height: 18px;
  }

  #password_reset_username {
    display: none;
  }

  .password-wrapper a.show-password,
  .password-wrapper a.hide-password {
    display: none;
  }
}
