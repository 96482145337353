$andy-not-sweaty-shirt-teal: #e8f4f2;

@mixin notification_box {
  width: auto;
  margin: 0 30px;
  font-weight: $font_weight_medium;
  font-size: 0.8rem;
  padding: 19px 20px 15px 47px;
  background-size: 55px;
  background-color: $some-grey;
  border: 1px solid $extra-light-grey;

  @media #{$large-up} {
    float: left;
    width: 42.1em;
  }
}

.show-hide-password {
  input[type='password'],
  input[type='text'] {
    padding-right: 53px !important;
    &.caps-on {
      padding-right: 78px !important;
    }
    // Hides MS Edge password reveal functionality
    &::-ms-reveal {
      display: none;
    }
    // Hides Safari caps indicator
    &::-webkit-caps-lock-indicator {
      position: absolute;
      overflow: hidden;
      clip: rect(0 0 0 0);
      height: 1px;
      width: 1px;
      margin: -1px;
      padding: 0;
      border: 0;
    }
  }

  .show-password,
  .hide-password {
    cursor: pointer;
    margin-left: -42px;
    position: relative;
    svg {
      fill: $shale-grey;

      &:hover {
        fill: $dark-grey;
      }
    }
  }

  .hide-password {
    top: 1.5px;
  }

  caps-lock-indicator {
    margin-left: -44px;
    position: relative;
    top: 2px;
    svg {
      fill: $shale-grey;

      &:hover {
        fill: $dark-grey;
      }
    }
  }
}
