// Reset password confirmation form
.reset_password_confirmation {
  font-size: rem(14);
  text-align: center;

  h1 {
    display: none;
  }
  p {
    line-height: 1.4;
  }

  .email-notification {
    font-size: rem(18);
    margin-bottom: 20px;
  }
}
