body.basic_registrations_container {
  @include checkbox;

  section.main_body div.site-width {
    margin-top: 5vh;

    article.basic_register_form {
      @media #{$mobile} {
        h1 {
          font-size: 14px;
        }
      }

      p#already-a-member {
        font-size: 14px;
        margin-bottom: 5px;
      }

      input {
        border: 1px solid $shale-grey;
        margin-top: 10px;
        margin-bottom: 0px;

        &.ng-touched.ng-invalid {
          border: 1px solid $school-bus-yellow;
        }
      }

      select.country_code {
        @extend %uni-select;

        border: 1px solid $shale-grey;
        font-size: 16px;
        height: 55px;
        margin-top: 10px;
        padding-left: 10px;
        width: 100%;
        -webkit-appearance: none;
        -webkit-border-radius: 0px;
      }

      li#email-preference-container {
        margin: 20px 0;

        div#checkbox {
          display: inline-block;
          margin-top: 5px;
          vertical-align: top;
          width: 10%;

          input.email-check-box {
            height: auto;
            margin-right: 5px;
          }
        }

        div#register-email-text {
          display: inline-block;
          font-size: 13px;
          line-height: 18px;
          width: 90%;
        }
      }

      p#accept-account-terms {
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 15px;
      }

      .tax-message {
        font-size: 12px;
        line-height: 18px;
        margin-top: 20px;
      }

      .ng-active {
        background-color: $school-bus-yellow;
        font-size: rem(14);
        line-height: 18px;
        margin-top: 3px;
        padding: 10px;
        transition: all 0.3s ease-out;
        width: 100%;
        display: block;
        height: auto;
      }

      ng-message {
        padding: 0;
        min-height: 0;
        margin: 0;
      }
    }
  }
  .join-recaptcha {
    margin-top: 15px;

    .g-recaptcha {
      transform: scale(0.78);
      transform-origin: 0 0;

      @media (min-width: 376px) {
        transform: none;
      }
    }
  }
}
