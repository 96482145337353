h1 {
  font-size: $h2-font-size;
  font-weight: normal;
}

a {
  font-weight: $font_weight_medium;
}

ol li input {
  height: 55px;
}

header {
  margin-bottom: 10px;
}

.with-create-account-button header {
  margin-bottom: 24px;
}

label {
  display: none;
}

input[type='password'],
input[type='text'] {
  border: 1px solid black;
  box-shadow: none;
  font-size: rem(16);
  margin-bottom: 10px;
  padding: 10px;
  width: 100%;
}
input:focus {
  border: 2px solid $shale-grey;
  box-shadow: none;
  outline: none;
}

footer {
  font-size: rem(14);
  text-align: center;
}

body.basic_registrations_container,
body.sessions_container,
body.oauth_container,
body.password_resets_container {
  footer.minimal_footer {
    position: relative;
  }
}
button:not(header button) {
  @include banshee-button($bg: $primary-button-bg-color);
  width: 100%;
}

.account_register {
  display: none;
}

.logo {
  background-color: black;
  height: 80px;
  position: relative;
  text-align: left;
  top: -30px;
  left: -30px;
  width: calc(100% + 60px);

  @media #{$mobile} {
    top: -20px;
    left: -20px;
    width: calc(100% + 40px);

    svg {
      height: 75px;
      position: absolute;
      top: -8px;
    }
  }

  .clickable,
  .non-clickable {
    display: block;
    margin: 0 auto;
    width: 108px;

    img,
    svg {
      height: 108px;
      margin-top: -13px;
      position: absolute;
      width: 108px;
    }
  }
}

.join-member {
  font-size: rem(14);
  margin: 5px 0;
}

.sign-in-agreements {
  font-size: 12px;
}

p.back-to-sign-in {
  display: block;
  font-size: rem(14);
  text-align: center;
  margin-top: 15px;
}

.checkmark {
  transition: max-height 0.25s ease-in;
  margin: 0 auto;
  max-height: 150px;
  min-height: 150px;
  padding-bottom: 20px;
}

.alert_box {
  background-color: $school-bus-yellow;
  font-size: rem(14);
  line-height: 18px;
  margin-bottom: 20px;
  padding: 10px;
  transition: all 0.3s ease-out;

  & > p {
    margin: 0;
  }
}

.message {
  text-align: center;

  .message-body {
    margin-top: 12px;
  }
}

.message .message-body strong {
  font-weight: $font_weight_medium;
}

.message .primary-cta {
  width: 100%;
}

.create-account-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $white;
  color: $dark-grey;
  border: 1px solid $shale-grey;
  padding: 15px 30px;
  border-radius: 4px;
  margin-bottom: 32px;

  svg {
    margin-right: 14px;
    fill: $dark-grey;
    height: 17px;
    width: 13px;
  }

  &:hover {
    background: $dark-grey;
    color: $white;

    svg {
      fill: $white;
    }
  }
}

.with-create-account-button .forgot-password {
  font-size: rem(14);
}

.or-divider {
  height: 17px;
  margin: 14px 0px 32px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  span.text {
    font-size: rem(14);
    background: $white;
    padding: 0 8px;
    z-index: 1;
  }

  hr {
    height: 1px;
    background: $border-grey;
    border-width: 0;
    position: absolute;
    width: 100%;
  }
}

.with-create-account-button .sign-in-agreements {
  margin-bottom: 0px;
  line-height: rem(18);
}
