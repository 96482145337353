// Forgot password form
.forgot_password {
  p#it-happens {
    display: block;
    font-size: rem(14);
    line-height: 18px;
    margin-bottom: 15px;
    margin-top: 5px;
  }

  #if-forgotten-password,
  #cant-find-email,
  #do-you-remember-password {
    display: none;
  }
}
