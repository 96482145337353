body.free-trial {
  .site-width {
    max-width: 955px;
  }

  .hero-details {
    display: none;

    @media #{$istock-xlarge-up} {
      display: block;
    }
  }

  .free-trial__container {
    display: flex;
    flex-direction: column-reverse;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;

    @media #{$istock-medium-up} {
      flex-direction: row;
      padding: 0 20px;
      max-width: none;
    }
  }

  .free-trial__link {
    padding-left: 50px;
    font-size: $rem-base - 2;

    @media #{$istock-medium-up} {
      font-size: inherit;
    }
  }

  .free-trial__section {
    flex: 1;

    @media #{$istock-medium-up} {
      padding: 25px 0;
    }

    header {
      h1 {
        font-size: 1.5rem !important;
      }
    }
  }

  .free-trial__divider {
    background-color: $border-grey;
    height: 1px;
    margin: 25px 0;

    @media #{$istock-medium-up} {
      height: auto;
      width: 1px;
      margin: 0 50px;
    }
  }

  .free-trial__title {
    font-weight: 500;
    margin-bottom: 30px;
    font-size: $h1-font-size - rem(6) !important;

    @media #{$istock-medium-up} {
      font-size: $h1-font-size !important;
    }
  }

  .free-trial__commitment-disclaimer {
    background: $some-grey;
    padding: 24px;
    margin-bottom: 24px;
    border-radius: 8px;

    p.free-trial__commitment-disclaimer-message {
      font-size: rem(14);
      line-height: 24px;
      font-weight: 500;
    }

    ul.free-trial__commitment-disclaimer-requirements {
      list-style: outside;
      margin: 0 0 0 20px;

      li {
        font-size: rem(14);
        line-height: 24px;
      }
    }
  }

  .free-trial__items {
    display: grid;
    grid-template-columns: 30px 1fr;
    gap: 20px;
    align-items: center;
    font-size: $rem-base - 2;
    padding-bottom: 24px;

    @media #{$istock-medium-up} {
      font-size: inherit;
    }

    strong {
      font-weight: 500;
    }
  }

  .free-trial__icon {
    max-height: 35px;
  }
}
